import { ICase, SteerageICaseWithCaseCategory } from '~/models/Case'
import { PriorAuthorizationItemType, ServiceCategory } from '../PriorAuthorization/types'
import { Person, PhysicianUser, Availability } from '@fireflyhealth/core'
import { ProviderDetailWithAdditionalData } from '../ProviderSearch/ProviderListItem'

export interface States {
  abbreviation: string | null
  id: number
  name: string | null
  can_service: boolean
  created_at: string | null
  created_by: string | null
  updated_at: string | null
  updated_by: string | null
}

export enum SteerageProviderStatus {
  UnderReview = 'under_review',
  Accepted = 'accepted',
  Rejected = 'rejected',
}

export interface Partnership {
  id: number
  partnershipType: string | null
  agreementType: string | null
  partnerName: string | null
}

export interface SteerageProvider {
  id: number
  addressLine1: string | null
  addressLine2: string | null
  careOrganizationName: string | null
  city: string | null
  dataSource: string | null
  fax: number | null
  firstName: string | null
  lastName: string | null
  npi: number | null
  phone: number | null
  sourceIdentifier: string | null
  specialtyList: string[] | null
  streetAddress: string | null
  zipCode: number | null
  state: States | null
  reasonForException: string | null
  memberSelectedAt: string | null
  recommendationStatus: string | null
  waiverLevel: string | null
  networkPartnerAgreementType?: string | null
  liveAvailability: Availability | null
  hasVerifiedContactDetails: boolean | null
  status: SteerageProviderStatus
  reviewed_at: string | null
  reviewed_by: number | null
  isSystemSuggested: boolean
  searchResult: ProviderDetailWithAdditionalData | null
  providerType?: string | null
  partnership?: Partnership | null
}

export interface CarePass {
  pk: number
  person: number | null
  serviceCategories: Array<ServiceCategory> | null
  priorAuthorization: PriorAuthorizationItemType | null
  createdAt: string | null
  cases?: Array<ICase> | null
  isActive?: boolean
}

export interface Steerage {
  id: number
  cases?: Array<SteerageICaseWithCaseCategory> | null
  createdAt: string | null
  description: string | null
  effectiveFrom?: string | null
  effectiveThrough?: string | null
  hasReferral: boolean
  hasWaiver: boolean
  hasActiveWaiver: boolean | null
  isWaiverIncluded: boolean | null
  isEffectivelyReferralOnly: boolean | null
  isEffectivelyWaiverOnly: boolean | null
  isEffectivelyReferralAndWaiver: boolean | null
  isLocked: boolean | null
  locationType: number | null
  lockedBy: number | null
  lockedAt: string | null
  person: number
  referral?: Referral
  requestType?: string | null
  schedulingDate?: string | null
  serviceQuantity: SteerageServiceQuantity | null
  specialtyGroup: number
  steerageProviders?: Array<SteerageProvider> | null
  status: string
  waiver?: CarePass | null
  typeOfVisit: string | null
  activeCaseId?: number | null
  priority: string | null
  notifyClinicianYn: boolean | null
  requestedByMember: boolean | null
  preferredProviderOrFacility: string | null
  hasElationReferralLetterOrOrder: boolean | null
  wasElationLetterCreatedFromLucian: boolean | null
  segment: string | null
  memberRequestedData?: MemberRequestedData | null
  isInitiatedFromLucian: boolean | null
  recommendationSentAt: string | null
  services?: Service[] | null
  priorAuthorizationRequired?: boolean | null
  priorAuthorizationSystemId?: string | null
}

export interface SteerageLockUnlock {
  createdAt: string | null
  createdBy: number
  id: number
  isLocked: boolean
  lockedAt: string | null
  lockedBy: string | null
  person: number
  updatedAt: string | null
  updatedBy: number
}

export interface ElationReferralTempate {
  body: string | null
}

export interface MemberRequestedData {
  specialty: string | null
  description: string | null
  goingOnFor: string | null
  providerName: string | null
  providerAddress: string | null
  practiceName: string | null
}

export interface ReferralInsuranceAuthorization {
  id: number
  status: string | null
  authorizationNumber: string | null
  useReferralProvider: boolean | null
  addressLine1: string | null
  addressLine2: string | null
  careOrganizationName: string | null
  city: string | null
  fax: number | null
  firstName: string | null
  lastName: string | null
  middleName: string | null
  npi: number | null
  phone: number | null
  zipCode: number | null
  state: number | null
}
export interface ReferralInsuranceAuthUnstructuredICDCode {
  icdCodeData: string | null
}

export interface ReferralInsuranceAuthICDCode {
  code: string | null
  description: string | null
}

export interface Referral {
  id: number
  body: string | null
  description: string | null
  referralRecipient: Array<ReferralRecipient> | null
  vendorCreateDate: string | null
  signOffUser: PhysicianUser
  person: Person | null
  insuranceAuthorizationRequired: boolean
  isLastMile: boolean | null
  referralInsuranceAuthorization: ReferralInsuranceAuthorization
  visitNotePriority: string | null
  leakageReason: string | null
  referralPriority: string | null
  referralInsuranceAuthUnstructuredIcdCode?: ReferralInsuranceAuthUnstructuredICDCode | null
  referralInsuranceAuthIcdCodes: Array<ReferralInsuranceAuthICDCode> | null
  createdAt: string | null
}

export interface ReferralRecipient {
  id: number
  referralSentYn: string | null
  referredTo: string | null
}

export interface SteerageServiceQuantity {
  value: number | null
  unit: string | null
}

export enum SteerageProviderDataSource {
  Firefly = 'firefly',
  Ribbon = 'ribbon',
  Manual = 'manual',
}

export enum RecommendationStatus {
  NO_RATING = 'NO_RATING',
  RECOMMENDED = 'RECOMMENDED',
  NOT_RECOMMENDED = 'NOT_RECOMMENDED',
  NEUTRAL = 'NEUTRAL',
}

export enum SteerageProviderWaiverLevel {
  Provider = 'Specific Provider',
  Facility = 'Entire Practice',
}

export interface SpecialtyGroup {
  id: number
  label: string
  isDeprecated: boolean
  services: Service[]
}

export interface SpecialtyDropdownOption {
  id: number
  label: string
  source: string
  services?: Service[] | null
  isDeprecated?: boolean
}

export interface ServiceType {
  id: number
  description: string
  ordering: number
}

export interface Service {
  id: number
  description: string
  category: ServiceType
}

export interface PersonPreference {
  id: number
  person: number
  preference: string
}

export const ReferralPriority = {
  STANDARD: 'Standard',
  MEDIUM: 'Soonest Available',
  URGENT: 'Urgent',
}

export const SteerageSegment = {
  HIGH_TCOC: 'High TCoC',
  LOW_TCOC: 'Low TCoC',
  COMMODITY: 'Commodity',
}

export enum CuratedProviderType {
  VIRTUAL = 'Virtual',
  AT_HOME_TEST_KIT = 'At Home Test Kit',
  IN_HOME = 'In Home',
  IN_PERSON = 'In Person',
}

export enum AgreementTypeConfig {
  DIRECT_CONTRACT = 'direct_contract',
  PREFERRED_VENDOR = 'preferred_vendor',
  CLIENT_CONTRACT = 'client_contract',
}
