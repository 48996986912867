import React, { useMemo } from 'react'
import type { FC } from 'react'
import { useState } from 'react'
import { Link as ReactRouterLink } from 'react-router-dom'
import {
  Box,
  Chip,
  Collapse,
  Popover,
  Table,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Link,
  Button,
  TableBody,
  Card,
  List,
  ListItem,
  ListSubheader,
  Divider,
} from '@mui/material'
import DoneIcon from '@mui/icons-material/Done'
import ArrowRight from '@mui/icons-material/ArrowRight'
import ArrowDropDown from '@mui/icons-material/ArrowDropDown'
import { format, parse } from 'date-fns'
import { Person } from '@fireflyhealth/core'

import Loader from '~/components/Loader'
import { useSteerages } from '~/api/SteerageService'
import {
  SteerageProvider,
  Steerage,
  SteerageProviderStatus,
} from '~/components/PatientDetail/SteerageView/types'
import { CaseCategoryUniqueEnum, CaseStatusEnum } from '~/models/Case'

export interface NavigationListProps {
  person: Person
}

// Hardcoded date for referral validation
const showReferralThroughDate = new Date('2021-01-01')

const NavigationList: FC<NavigationListProps> = props => {
  const { data: steerages, isLoading } = useSteerages(props.person.id)
  // It's used to set the position of the popover
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
  const [selectedProviderPopOverData, setSelectedProviderPopOverData] =
    useState<Array<SteerageProvider> | null>(null)
  // used for collapsable row for the table
  const [open, setOpen] = useState<number | null>(null)

  const handleProviderPopoverOpen = (
    event: React.MouseEvent<HTMLElement>,
    providers: SteerageProvider[] | null
  ) => {
    setAnchorEl(event.currentTarget)
    setSelectedProviderPopOverData(providers)
  }

  const handlePopoverClose = () => {
    setAnchorEl(null)
    setSelectedProviderPopOverData(null)
  }

  const getCreatedDate = (steerage: Steerage) => {
    // If this Steerage has a Referral, this should be the Elation Created Date
    // If this Steerage does not have a Referral, then this should be the Waiver’s Created Date

    if (steerage?.hasReferral && steerage?.referral?.vendorCreateDate)
      return parse(steerage?.referral?.vendorCreateDate)
    if (steerage?.hasWaiver && steerage?.waiver?.createdAt) {
      return parse(steerage?.waiver?.createdAt)
    }
    return null
  }

  const getFormattedCreatedDate = (steerage: Steerage) => {
    const createdDate = getCreatedDate(steerage)
    if (createdDate) return format(createdDate, 'MM/DD/YYYY')
    return ''
  }

  const getFilteredSteerageProvider = (steerage: Steerage) => {
    let filteredSteerageProvider = steerage?.steerageProviders?.filter(
      provider => provider.status === SteerageProviderStatus.Accepted
    )
    return filteredSteerageProvider ?? []
  }

  const showProviderNameColumnData = (providers: SteerageProvider[]) => {
    // first show the provider which is selected by member
    // if there is not provider selected by member then just show the first provider
    const memberSelectedProvider = providers?.find(provider => provider?.memberSelectedAt != null)

    if (memberSelectedProvider) {
      // if facility name present then show that otherwise provider name
      return memberSelectedProvider?.careOrganizationName
        ? memberSelectedProvider?.careOrganizationName
        : memberSelectedProvider.firstName + ' ' + memberSelectedProvider.lastName
    }
    // get the first provider and show that
    const provider = providers[0]
    return provider?.careOrganizationName
      ? provider?.careOrganizationName
      : provider?.firstName + ' ' + provider?.lastName
  }

  const steerageListValidation = (steerage: Steerage) => {
    // Do not show row if
    // The Steerage is tied to a case of category “Referral - Location Search - Care Only”
    // OR “Care Pass from Referral (Care + Coverage)”
    // AND that Case is in status “Will Not Do”
    // AND that Steerage description is empty
    let result = steerage?.cases?.map(steerageCase => {
      if (
        (steerageCase?.category?.uniqueKey ==
          CaseCategoryUniqueEnum.referrals_location_search_care_only ||
          steerageCase?.category?.uniqueKey ==
            CaseCategoryUniqueEnum.care_pass_referral_care_and_coverage ||
          steerageCase?.category?.uniqueKey ==
            CaseCategoryUniqueEnum.care_pass_member_request_coverage_only) &&
        steerageCase?.status == CaseStatusEnum.will_not_do &&
        !steerage.description
      ) {
        return false
      }
    })
    if (result?.includes(false)) return false

    // Only include Referrals through January 2021
    if (
      steerage.isEffectivelyReferralOnly &&
      steerage?.referral?.createdAt &&
      parse(steerage.referral.createdAt) < showReferralThroughDate
    ) {
      return false
    }

    return true
  }

  const getSteerageType = (steerage: Steerage) => {
    // We need to show the type of steerage
    if (steerage.isEffectivelyReferralAndWaiver) return 'Referral w/Care Pass'
    if (steerage.isEffectivelyReferralOnly) return 'Referral'
    if (steerage.isEffectivelyWaiverOnly) return 'Care Pass'
    return ''
  }

  const sortSteeragesByCreatedDate = (steerages: Steerage[]) => {
    // This method sorts the created date column
    return steerages.sort((steerageA, steerageB) => {
      let createdDateA = getCreatedDate(steerageA)
      let createdDateB = getCreatedDate(steerageB)
      if (!createdDateA || !createdDateB) return 0
      return createdDateA.getTime() - createdDateB.getTime()
    })
  }

  const schedulingDates = useMemo(() => {
    const dates: Record<string, string | null> = {}
    for (const steerage of steerages ?? []) {
      if (steerage.schedulingDate) {
        const formatted = format(parse(steerage.schedulingDate), 'MMM DD, YYYY')
        dates[steerage.id] = 'Scheduled ' + formatted
      } else {
        dates[steerage.id] = null
      }
    }
    return dates
  }, [steerages])

  return (
    <Box>
      <Box mt={2} ml={2}>
        <Typography variant="subtitle1" fontWeight="500">
          Network Navigation
        </Typography>
      </Box>
      {isLoading ? <Loader /> : null}
      {/* Pop over for the provider list */}
      <Popover
        style={{
          pointerEvents: 'none',
        }}
        open={selectedProviderPopOverData && selectedProviderPopOverData.length > 0 ? true : false}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        {selectedProviderPopOverData?.map(provider => (
          <Box px={2} py={1} key={provider?.id}>
            <Typography variant="caption">
              {provider?.careOrganizationName
                ? provider?.careOrganizationName
                : provider?.firstName + ' ' + provider?.lastName}{' '}
              {provider.memberSelectedAt ? (
                <DoneIcon fontSize="small" style={{ verticalAlign: 'bottom' }} />
              ) : null}
            </Typography>
          </Box>
        ))}
      </Popover>
      <Table
        aria-label="sticky table"
        sx={t => ({ 'td, th': { borderColor: t.palette.grey[100] } })}
      >
        <TableHead>
          <TableRow>
            <TableCell width="30%">Description</TableCell>
            <TableCell width="30%">Providers</TableCell>
            <TableCell width="20%">Created Date</TableCell>
            <TableCell width="20%">Cases</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {steerages &&
            sortSteeragesByCreatedDate(steerages).map(
              steerage =>
                steerageListValidation(steerage) && (
                  <React.Fragment key={steerage.id}>
                    <TableRow key={steerage.id}>
                      <TableCell component="th" scope="row" sx={{ borderBottom: 0 }}>
                        {/* Show 'None' when description is null */}
                        {steerage.description ?? 'None'} {' - '} {getSteerageType(steerage)}
                      </TableCell>
                      <TableCell align="left" sx={{ borderBottom: 0 }}>
                        {steerage && getFilteredSteerageProvider(steerage)?.length > 0 && (
                          <Box
                            onMouseEnter={event =>
                              handleProviderPopoverOpen(
                                event,
                                getFilteredSteerageProvider(steerage) ?? null
                              )
                            }
                            onMouseLeave={handlePopoverClose}
                          >
                            {showProviderNameColumnData(getFilteredSteerageProvider(steerage))}{' '}
                            {getFilteredSteerageProvider(steerage)?.length > 1 ? '...' : ''}
                          </Box>
                        )}
                      </TableCell>
                      <TableCell align="left" sx={{ borderBottom: 0 }}>
                        {getFormattedCreatedDate(steerage)}
                      </TableCell>
                      <TableCell sx={{ borderBottom: 0 }}>
                        <Button
                          onClick={() => setOpen(open == steerage?.id ? null : steerage?.id)}
                          startIcon={open == steerage?.id ? <ArrowDropDown /> : <ArrowRight />}
                        >
                          {steerage?.cases?.length + ' case(s)'}
                        </Button>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell colSpan={4} sx={{ borderBottom: 0, pt: 0 }}>
                        <Chip size="small" label={steerage.status} />
                        {schedulingDates[steerage.id] ? (
                          <Chip size="small" label={schedulingDates[steerage.id]} sx={{ ml: 1 }} />
                        ) : null}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={4}>
                        <Collapse in={open == steerage?.id} timeout="auto" unmountOnExit>
                          <Card sx={{ mb: 2 }}>
                            <ListSubheader>Cases</ListSubheader>
                            <Divider />
                            {steerage?.cases && (
                              <List>
                                {steerage.cases.map(steerageCase => (
                                  <ListItem key={steerageCase.id}>
                                    <Link
                                      component={ReactRouterLink}
                                      to={`todos?caseId=${steerageCase.id}`}
                                    >
                                      {steerageCase?.category?.title}
                                    </Link>
                                    {steerageCase?.status ? (
                                      <Chip
                                        size="small"
                                        sx={{ ml: 1, fontWeight: 'initial' }}
                                        label={steerageCase?.status}
                                      />
                                    ) : null}
                                  </ListItem>
                                ))}
                              </List>
                            )}
                          </Card>
                        </Collapse>
                      </TableCell>
                    </TableRow>
                  </React.Fragment>
                )
            )}
        </TableBody>
      </Table>
    </Box>
  )
}

export default NavigationList
