import Loader from '~/components/Loader'
import { useSteerage } from '~/api/SteerageService'
import { Steerage } from './types'
import { SteerageViewV3 } from './SteerageViewV3/SteerageViewV3'

const SteerageRelatedView = ({ model }: { model: Pick<Steerage, 'id' | 'activeCaseId'> }) => {
  return <SteerageEditor id={model.id} activeCaseId={model.activeCaseId ?? null} />
}
// Helper for fetching the full Steerage prior to rendering the edit form.
const SteerageEditor: React.FC<{
  id: Steerage['id']
  activeCaseId: number | null
}> = ({ id, activeCaseId }) => {
  const { data: steerage, isLoading } = useSteerage(id)
  if (steerage) steerage.activeCaseId = activeCaseId

  if (isLoading || !steerage) {
    return <Loader inline={true} />
  }

  return <SteerageViewV3 steerageId={id} />
}
export default SteerageRelatedView
