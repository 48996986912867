import { apiClient } from '~/api/rest'
import { useQuery, useMutation } from '~/components/Providers/ApiProvider'
import { UseMutationOptions, useQueryClient } from 'react-query'
import { queueNotification } from '~/redux/actions/notifications'
import { useDispatch } from 'react-redux'
import {
  Steerage,
  SteerageProvider,
  States,
  ReferralInsuranceAuthUnstructuredICDCode,
  Service,
  PersonPreference,
  ElationReferralTempate,
  SteerageProviderStatus,
  SteerageLockUnlock,
} from '../components/PatientDetail/SteerageView/types'
import { ServiceCategory } from '../components/PatientDetail/PriorAuthorization/types'
import { invalidateCachedCaseData } from './CaseService'
import { listPersonTodoKey } from './PatientToDoService'

export interface LocationType {
  id: number
  createdAt: any
  updatedAt: any
  label: string
  services?: Service[] | null
}
export interface ToggleSteerageLockInterface {
  steerageId: number
  isLocked: Boolean
  description?: string | null
  effectiveFrom?: string | null
  effectiveThrough?: string | null
  person?: number | null
  requestType?: string | null
  schedulingDate?: string | null
  unit?: string | null
  value?: number | null
}

export interface CarePassServiceCategory {
  serviceCategoryIds?: number[]
  isActive?: boolean
}

export interface ReferralInsuranceAuthorization {
  authorizationNumber: string | null
  status: ReferralInsuranceAuthorizationStatuses | null
  useReferralProvider?: boolean
}

export enum ReferralInsuranceAuthorizationStatuses {
  pending_approval = 'Pending Approval',
  pending_clinical_info = 'Pending Clinical Info',
  pending_oon = 'Pending OON Approval',
  date_change = 'Provider/Date Change',
  denied = 'Denied',
  accepted = 'Accepted',
}

export interface ReferralPayload {
  insuranceAuthorizationRequired: boolean | null
  isLastMile: boolean | null
  referralInsuranceAuthorization: ReferralInsuranceAuthorization
  leakageReason: string | null
  visitNotePriority: string | null
  referralInsuranceAuthUnstructuredIcdCode?: ReferralInsuranceAuthUnstructuredICDCode | null
  body?: string | null
}

export interface EditSteerageInterface {
  effectiveFrom?: string | null
  effectiveThrough?: string | null
  description?: string | null
  requestType?: string | null
  schedulingDate?: string | null
  serviceQuantity?: {
    value: string | number | null
    unit: string | null
  }
  requestedByMember?: boolean | null
  specialtyGroup?: number | null
  locationType?: number | null
  steerageId: number
  waiver?: CarePassServiceCategory
  referral?: ReferralPayload
  typeOfVisit?: string | null
  sendToElation?: boolean | null
  priority?: string | null
  memberRequestedData?: {
    providerName?: string | null
  }
  serviceIds?: number[] | null
  priorAuthorizationRequired?: boolean
  priorAuthorizationSystemId?: string | null
}

export interface GetElationLetterTemplateInterface {
  personId: number
  isLastMile?: boolean | null
  priority?: string | null
  locationType?: string | null
  typeOfVisit?: string | null
  specialtyGroup?: string | null
  preferredProvider?: string | null
  memberPreferredProvider?: string | null
  services?: string | null
}

export interface EditSteerageProviderInterface {
  firstName: string | null
  lastName: string | null
  npi: string | number | null
  specialtyList: string[] | undefined | null
  careOrganizationName: string | null
  addressLine1: string | null
  addressLine2: string | null
  city: string | null
  state_id: number | null
  zipCode: string | number | null
  phone: string | number | null
  fax: string | number | null
  reasonForException: string
  carePassId?: number | null
  providerId?: number | null
  waiverLevel?: string | null
  hasVerifiedContactDetails?: boolean | null
  isManuallyEdited?: boolean | null
}

export interface EditInsuranceAuthProviderInterface {
  firstName?: string | null
  middleName?: string | null
  lastName?: string | null
  npi?: string | number | null
  careOrganizationName?: string | null
  addressLine1?: string | null
  addressLine2?: string | null
  city?: string | null
  state?: string | number | null
  zipCode?: string | number | null
  phone?: string | number | null
  fax?: string | number | null
  referral: string | number | null
  insuranceAuthId?: string | number | null
  useReferralProvider?: boolean
}

export interface AddSteerageProviderInterface {
  firstName: string | null
  lastName: string | null
  npi: string | number | null
  specialtyList: string[] | undefined | null
  careOrganizationName: string | null
  addressLine1: string | null
  addressLine2: string | null
  city: string | null
  state_id: number | null
  zipCode: string | number | null
  phone: string | number | null
  fax: string | number | null
  reasonForException?: string
  carePassId?: number | null
  dataSource?: string | null
  waiverLevel?: string | null
  networkPartnerAgreementType?: string | null
  specialtyGroup?: number | null
  hasVerifiedContactDetails?: boolean | null
  compositeScore?: number | null
  costScore?: number | null
  qualityScore?: number | null
  averageRating?: number | null
  numberOfRating?: number | null
  recommendationReason?: string[] | null
  recommendationStatus?: string | null
  locationConfidenceScore?: number | null
  languages?: string[] | null
  distance?: number | null
}

export interface DeleteSteerageProviderInterface {
  steerageId?: number | null
  providerId?: number | null
}

export interface EditSteerageProviderWaiverLevelInterface {
  steerageId: number | null
  providerId: number | null
  waiverLevel: string | null
}

export interface SteerageProviderSelectUnselectInterface {
  steerageId: number | null
  providerId?: number | null
}

export interface PersonPreferenceCreateUpdateInterface {
  preference: string | null
  person: number
  personPreferenceId?: number
}

/**
 * useSteerages for fetching all steerages by person id
 * @param pk: carePassId
 * @returns care passes
 */
export const listSteeragesForPerson = 'listSteeragesForPerson'
export const useSteerages = (personId: number) => {
  return useQuery(
    [listSteeragesForPerson, personId],
    () => apiClient.rest.get<Array<Steerage>>(`/referral/steerage/person/${personId}/`),
    {},
    {
      error: `Failed to fetch Steerages for ${personId}`,
    }
  )
}

/**
 * editSteerage
 * @param steerageId: number
 * @returns steerage
 */
const editSteerage = (payload: EditSteerageInterface): Promise<Steerage> => {
  if (payload?.sendToElation) {
    delete payload?.sendToElation
    return apiClient.rest.patch(
      `/referral/steerage/${payload.steerageId}/?send_to_elation=True`,
      payload
    )
  } else {
    return apiClient.rest.patch(`/referral/steerage/${payload.steerageId}/`, payload)
  }
}

/**
 * getElationLetterTemplate
 * @param steerageId: number
 * @returns steerage
 */
const getElationLetterTemplate = (
  payload: GetElationLetterTemplateInterface
): Promise<ElationReferralTempate> => {
  return apiClient.rest.get(
    `/referral/steerage/person/${payload.personId}/elation-referral-template/`,
    payload
  )
}

export const useEditSteerage = () => {
  const dispatch = useDispatch()
  const client = useQueryClient()

  return useMutation(
    (payload: EditSteerageInterface) => editSteerage(payload),
    {
      onSuccess: async (payload: Steerage) => {
        await Promise.all([
          client.invalidateQueries([listSteerageKey, payload?.id]),
          client.invalidateQueries([listSteeragesForPerson, payload.person]),
          payload.person ? invalidateCachedCaseData(client, payload.person) : Promise.resolve(),
        ])
        dispatch(
          queueNotification({
            message: `Saved details successfully`,
            variant: 'success',
          })
        )
      },
    },
    { error: `Failed to save details` }
  )
}

export const useGetElationLetterTemplate = () => {
  const dispatch = useDispatch()
  return useMutation(
    (payload: GetElationLetterTemplateInterface) => getElationLetterTemplate(payload),
    {
      onSuccess: async () => {
        dispatch(
          queueNotification({
            message: `Created template successfully`,
            variant: 'success',
          })
        )
      },
    },
    {
      error: `Failed to create template`,
    }
  )
}

/**
 * toggleSteerageLock
 * @param steerageId: number
 * @param isLocked: boolean
 * @returns steerage
 */
const toggleSteerageLock = (payload: ToggleSteerageLockInterface) => {
  return payload.isLocked == true
    ? apiClient.rest.patch<SteerageLockUnlock>(
        `/referral/steerage/${payload.steerageId}/toggle_lock/?is_locked=False`
      )
    : apiClient.rest.patch<SteerageLockUnlock>(
        `/referral/steerage/${payload.steerageId}/toggle_lock/?is_locked=True`
      )
}

export const useToggleSteerageLock = () => {
  const dispatch = useDispatch()
  const client = useQueryClient()

  return useMutation(
    (payload: ToggleSteerageLockInterface) => toggleSteerageLock(payload),
    {
      onSuccess: async payload => {
        await Promise.all([
          client.invalidateQueries([listPersonTodoKey, payload.person]),
          client.invalidateQueries([listSteerageKey]),
          invalidateCachedCaseData(client, payload.person),
        ])
        dispatch(
          queueNotification({
            message: `Steerage ${payload.isLocked ? 'locked' : 'unlocked'} successfully`,
            variant: 'success',
          })
        )
      },
    },
    { error: `Failed to toggle lock on Steerage` }
  )
}

// API for fetching all Steerage providers
const fetchSteerageProviders = (steerageId: number | null) => {
  return apiClient.rest.get<SteerageProvider[]>(`/referral/steerage/${steerageId}/providers/`)
}
export const listSteerageSteerageProvidersKey = 'listSteerageSteerageProviders'
export const useSteerageProviders = (steerageId: number | null) => {
  return useQuery(
    [listSteerageSteerageProvidersKey, steerageId],
    () => fetchSteerageProviders(steerageId),
    {
      enabled: !!steerageId,
    },
    {
      error: `Failed to fetch Steerage providers for ${steerageId}`,
    }
  )
}

// API for fetching all service categories for care pass
const fetchServiceCategories = (personId): Promise<Array<ServiceCategory>> => {
  return apiClient.rest.get(
    `/health-plan/person/${personId}/service-categories?is_authorization_specific=False`
  )
}
export const listCarePassServiceCategoriesKey = 'listCarePassServiceCategories'
export const useCarePassServiceCategories = personId => {
  return useQuery(
    [listCarePassServiceCategoriesKey],
    () => fetchServiceCategories(personId),
    {},
    {
      error: `Unable to fetch service categories`,
    }
  )
}

// API for fetching all reason for exception
type ExceptionReason = 'manual' | 'default' | 'suggestion' | 'facility'
const getReasonForException = (reason?: ExceptionReason): Promise<string[][]> => {
  return apiClient.rest.get(`/referral/steerage/reason-for-exception/`, { reason })
}
export const useReasonForException = (reason?: ExceptionReason) => {
  return useQuery(
    ['reasonForException', reason],
    () => getReasonForException(reason),
    { staleTime: Infinity },
    {
      error: `Failed to load Reason for exception list`,
    }
  )
}

// API for fetching all states
const getStates = (): Promise<Array<States>> => {
  return apiClient.rest.get(`/states/`)
}
export const useStates = () => {
  return useQuery(
    [],
    () => getStates(),
    {},
    {
      error: `Unable to fetch states`,
    }
  )
}

/**
 * editSteerageSteerageProvider
 * @returns steerage provider
 */
const editSteerageSteerageProvider = (payload): Promise<SteerageProvider> => {
  return apiClient.rest.patch(
    `/referral/steerage/${payload.steerageId}/provider/${payload.providerId}/`,
    payload
  )
}
export const useEditSteerageSteerageProvider = () => {
  const dispatch = useDispatch()

  return useMutation(
    (payload: EditSteerageProviderInterface) => editSteerageSteerageProvider(payload),
    {
      onSuccess: async () => {
        dispatch(
          queueNotification({
            message: `Provider updated successfully`,
            variant: 'success',
          })
        )
      },
    },
    { error: `Failed to update provider for Steerage` }
  )
}

// API for creating exceptional provider for a Steerage
/**
 * useAddProviderToSteerage
 * @returns steerage provider
 */
export const addProviderToTheSteerage = (payload): Promise<SteerageProvider> => {
  return apiClient.rest.post(`/referral/steerage/${payload.steerageId}/providers/`, payload)
}
export const useAddProviderToSteerage = () => {
  const dispatch = useDispatch()

  return useMutation(
    (payload: AddSteerageProviderInterface) => addProviderToTheSteerage(payload),
    {
      onSuccess: async () => {
        dispatch(
          queueNotification({
            message: `Provider added to Steerage successfully`,
            variant: 'success',
          })
        )
      },
    },
    { error: `Failed to add provider to Steerage` }
  )
}

export const deleteProviderFromSteerage = payload => {
  return apiClient.rest.delete(
    `/referral/steerage/${payload.steerageId}/provider/${payload.providerId}/`
  )
}
export const useDeleteProviderFromSteerage = () => {
  const dispatch = useDispatch()

  return useMutation(
    (payload: DeleteSteerageProviderInterface) => deleteProviderFromSteerage(payload),
    {
      onSuccess: async () => {
        dispatch(
          queueNotification({
            message: `Provider deleted successfully from Steerage`,
            variant: 'success',
          })
        )
      },
    },
    { error: `Failed to delete provider from Steerage` }
  )
}

/**
 * useSteerage for fetching steerage by id
 * @param pk: SteerageId
 * @returns Steerage
 */
export const listSteerageKey = 'listSteerageKey'
export const useSteerage = (id: Steerage['id'] | null) => {
  return useQuery(
    [listSteerageKey, id],
    () => apiClient.rest.get<Steerage>(`/referral/steerage/${id}/`),
    {
      enabled: !!id,
    },
    {
      error: `Failed to fetch Steerage ${id}`,
    }
  )
}

/**
 * useSpecialtyGroup for fetching all specialties
 * @returns SpecialtyGroup[]
 */
const listSpecialtyGroups = 'listSpecialtyGroups'
export const useSpecialtyGroups = () => {
  return useQuery(
    [listSpecialtyGroups],
    () => apiClient.fact.getSpecialtyGroups(),
    {},
    {
      error: `Failed to fetch Specialty Groups`,
    }
  )
}

/**
 * getLocationTypesBasedOnProgram for fetching all location/facility types based on program
 * @returns LocationType[]
 */
export const getLocationTypesBasedOnProgram = (person_id): Promise<LocationType[]> => {
  return apiClient.rest.get(`/facts/location-types/${person_id}/`)
}

/**
 * useLocationType for fetching all location/facility types
 * @returns LocationType[]
 */
const listLocationTypes = 'listLocationTypes'
export const useLocationTypes = () => {
  return useQuery(
    [listLocationTypes],
    () => apiClient.fact.getLocationTypes(),
    {},
    {
      error: `Failed to fetch Location Types`,
    }
  )
}
/**
 * editInsuranceAuthProvider
 * @returns referral insurance authorization
 */
const editInsuranceAuthProvider = (payload): Promise<ReferralInsuranceAuthorization> => {
  return apiClient.rest.patch(
    `/referral/insurance-authorization/${payload.insuranceAuthId}/`,
    payload
  )
}

// For updating the referral insurance auth provider
export const useEditInsuranceAuthProvider = () => {
  const dispatch = useDispatch()

  return useMutation(
    (payload: EditInsuranceAuthProviderInterface) => editInsuranceAuthProvider(payload),
    {
      onSuccess: async () => {
        dispatch(
          queueNotification({
            message: `Provider updated successfully`,
            variant: 'success',
          })
        )
      },
    },
    { error: `Failed to update provider for Steerage` }
  )
}

/**
 * addInsuranceAuthProvider
 * @returns referral insurance authorization
 */
const addInsuranceAuthProvider = (payload): Promise<ReferralInsuranceAuthorization> => {
  return apiClient.rest.post(`/referral/insurance-authorization/`, payload)
}
// For adding the referral insurance auth provider
export const useAddInsuranceAuthProvider = () => {
  const dispatch = useDispatch()

  return useMutation(
    (payload: EditInsuranceAuthProviderInterface) => addInsuranceAuthProvider(payload),
    {
      onSuccess: async () => {
        dispatch(
          queueNotification({
            message: `Provider added successfully`,
            variant: 'success',
          })
        )
      },
    },
    { error: `Failed to update provider for Steerage` }
  )
}

/**
 * editSteerageProviderWaiverLevel
 */
const editSteerageProviderWaiverLevel = payload => {
  return apiClient.rest.patch(
    `/referral/steerage/${payload.steerageId}/provider/${payload.providerId}/update-waiver-level/`,
    payload
  )
}
export const useEditSteerageProviderWaiverLevel = () => {
  const dispatch = useDispatch()

  return useMutation(
    (payload: EditSteerageProviderWaiverLevelInterface) => editSteerageProviderWaiverLevel(payload),
    {
      onSuccess: async () => {
        dispatch(
          queueNotification({
            message: `Provider updated successfully`,
            variant: 'success',
          })
        )
      },
    },
    { error: `Failed to update provider for Steerage` }
  )
}

/**
 * selectSteerageProvider
 */
const selectSteerageProvider = payload => {
  return apiClient.rest.patch(
    `/referral/steerage/${payload.steerageId}/providers/${payload.providerId}/select/`,
    payload
  )
}
export const useSelectSteerageProvider = () => {
  const dispatch = useDispatch()

  return useMutation(
    (payload: SteerageProviderSelectUnselectInterface) => selectSteerageProvider(payload),
    {
      onSuccess: async () => {
        dispatch(
          queueNotification({
            message: `Provider selected successfully`,
            variant: 'success',
          })
        )
      },
    },
    { error: `Failed to select provider for Steerage` }
  )
}

/**
 * unselectSteerageProvider
 */
const unselectSteerageProvider = payload => {
  return apiClient.rest.patch(
    `/referral/steerage/${payload.steerageId}/providers/unselect/`,
    payload
  )
}
export const useUnselectSteerageProvider = () => {
  const dispatch = useDispatch()
  return useMutation(
    (payload: SteerageProviderSelectUnselectInterface) => unselectSteerageProvider(payload),
    {
      onSuccess: async () => {
        dispatch(
          queueNotification({
            message: `Provider removed successfully`,
            variant: 'success',
          })
        )
      },
    },
    { error: `Failed to removed provider for Steerage` }
  )
}

export const getNetworkPartnerDisplayName = (
  networkPartnerAgreementType: string,
  partnershipType: string | null = null
) => {
  let displayName = ''
  if (networkPartnerAgreementType == 'direct_contract') {
    displayName = 'Firefly Nearby'
  }
  if (
    (networkPartnerAgreementType == 'direct_contract' && partnershipType == 'Specialist') ||
    networkPartnerAgreementType == 'preferred_vendor'
  ) {
    displayName = 'Partner'
  }
  if (networkPartnerAgreementType == 'client_contract') {
    displayName = 'Client Point Solution'
  }
  return displayName
}

// API for fetching all the services
export const getServices = (): Promise<Service[]> => {
  return apiClient.rest.get(`/referral/steerage/last-mile-service/`)
}
const listServices = 'listServices'
export const useServices = () => {
  return useQuery(
    [listServices],
    () => getServices(),
    {},
    {
      error: `Failed to load last mile service list`,
    }
  )
}

/**
 * Endpoint for fetching person preference
 */
const getPersonPreference = personId => {
  return apiClient.rest.get(`/providers/me/user/person/${personId}/person-preference/`)
}
export const getPersonPreferenceKey = 'getPersonPreferenceKey'
export const useFetchPersonPreference = (personId: number) => {
  return useQuery(
    [getPersonPreferenceKey, personId],
    () => getPersonPreference(personId),
    {
      enabled: !!personId,
    },
    {
      error: `Failed to fetch Member preference`,
    }
  )
}

// API for creating Person preference
/**
 * useCreatePersonPreference
 * @returns PersonPreference
 */
export const createPersonPreference = (payload): Promise<PersonPreference> => {
  return apiClient.rest.post(
    `/providers/me/user/person/${payload.person}/person-preference/`,
    payload
  )
}
export const useCreatePersonPreference = () => {
  const dispatch = useDispatch()

  return useMutation(
    (payload: PersonPreferenceCreateUpdateInterface) => createPersonPreference(payload),
    {
      onSuccess: async () => {
        dispatch(
          queueNotification({
            message: `Member preference added successfully`,
            variant: 'success',
          })
        )
      },
    },
    { error: `Failed to add Member Preference` }
  )
}

// API for updating Person preference
/**
 * useUpdatePersonPreference
 * @returns PersonPreference
 */
export const updatePersonPreference = (payload): Promise<PersonPreference> => {
  const request = {
    preference: payload.preference,
  }
  return apiClient.rest.patch(
    `/providers/me/user/person/${payload.person}/person-preference/${payload.personPreferenceId}/`,
    request
  )
}
export const useUpdatePersonPreference = () => {
  const dispatch = useDispatch()

  return useMutation(
    (payload: PersonPreferenceCreateUpdateInterface) => updatePersonPreference(payload),
    {
      onSuccess: async payload => {
        dispatch(
          queueNotification({
            message: `Member preference ${
              payload?.preference ? 'updated' : 'deleted'
            } successfully`,
            variant: 'success',
          })
        )
      },
    },
    { error: `Failed to update Member Preference` }
  )
}

export const useReviewSteerageProviders = (
  steerageId: number | null | undefined,
  options?: UseMutationOptions<
    unknown,
    unknown,
    { reasonForRejection?: string; providers: number[]; approve: boolean }
  >
) => {
  const client = useQueryClient()
  return useMutation(
    async payload => {
      return apiClient.rest.post(`/referral/steerage/${steerageId}/providers/review/`, payload)
    },
    {
      ...options,
      onSuccess: (_, payload) => {
        client.cancelQueries([listSteerageKey, steerageId])
        client.invalidateQueries([listSteerageKey, steerageId])
        client.cancelQueries([listSteerageSteerageProvidersKey, steerageId])
        client.invalidateQueries([listSteerageSteerageProvidersKey, steerageId])

        client.setQueryData([listSteerageSteerageProvidersKey, steerageId], (oldData: any) => {
          for (const id of payload.providers) {
            const providerIndex = oldData.findIndex(p => p.id === id)
            if (providerIndex >= -1) {
              if (!payload.approve) {
                oldData[providerIndex].status = SteerageProviderStatus.Rejected
              } else {
                // We set a timer here to allow the UI to animate the change
                setTimeout(() => {
                  oldData[providerIndex].status = 'approved'
                }, 750)
              }
            }
          }
          return oldData
        })
      },
    }
  )
}
